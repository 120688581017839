import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Badge, Button } from "reactstrap";
import ShiftComparison from "../shift/shiftComparison.component";
import miscConfig from '../config/misc.config'
import ShiftCalendarDisplay from "../shift/shiftCalendarDisplay.component";
import CandidateService from "./../../services/candidate.service"
import chatService from "../../services/chat.service";
const MapCandidatesInRadiusWrapper = (props) => {
    const { id } = useParams();

    // Mocked Data
    const mockedCandidate = {
        similarities: {
            poste: true,
            distance: 7.8,
            shifts: 0.996,
            contract: true,
            salary: 1,
            software: false,
        },
        candidate: {
            id: 5493,
            name: "Condidat",
            last_name: "",
            city: "AUVERS SUR OISE - 95430",
            latitude: "49.0803858577",
            longitude: "2.15488070885",
            radius: 50,
            software: "[]",
            auto_entrepreneur: false,
            diploma_validated: false,
            cv_validated: false,
            kbis_validated: false,
            disponibility: {
                date_start: "2023-05-23T13:04:58.000Z",
                contract_type: "CDD",
                position: "pharmacien",
                shifts: "1000000000000000000000000000000000000000000000000000000000000000",
                shifts_flexibility: true,
                info: "Disponible pour les horaires flexibles.",
            },
        },
    };

    const [clickedCandidate, setClickedCandidate] = useState(mockedCandidate);

    const [condidatDetail, setCondidatDetail] = useState(null); // State for fetched offer details
    const [loading, setLoading] = useState(true); // State for loading indicator

    // Fetch offer details from API
    useEffect(() => {
        const fetchCondidatDetail = async () => {
            try {
                setLoading(true);
                // Replace with your API endpoint
                let response = await CandidateService.getCandidateInfo(id);
                console.log('------------ response ------------', response);
                console.log('----- offer');
                const data = response;
                setCondidatDetail(data);
            } catch (error) {
                console.error("Error fetching offer details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCondidatDetail();
    }, [id]);

    useEffect(() => {
        if (condidatDetail?.candidateName) {
            setTimeout(() => {
                document.title = `Condidat details - ${condidatDetail.candidateName}`;
            }, 500);
        }
    }, [condidatDetail]);

    if (loading) {
        return <div style={{ margin: "40px", padding: "0px" }}>Chargement...</div>;
    }

    if (!condidatDetail) {
        return <div style={{ margin: "40px", padding: "0px" }}>Aucun condidat trouvée.</div>;
    }

    const onDemandContact = () => {
        chatService.initialize(condidatDetail.candidateId)
        props.history.push("/chats#" + condidatDetail.candidateId)
    }

    return (
        <div style={{ margin: "10px", padding: "0px" }}>
            {(
                <div>
                    <div
                        className="overlay"
                        style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.5)" }}
                        onClick={() => setClickedCandidate(null)}
                    />
                    <div
                        className="information p-3 p-md-5"
                        style={{ position: "relative", zIndex: 1050, background: "white", borderRadius: "8px" }}
                    >
                        <Row>
                            <Col className="text-right">
                                <Button outline color="primary" onClick={() => this.deactivateOverlay()} className="mb--4 mr--4 bg-white" style={{ zIndex: "1080" }}><i className="fas fa-times fa-lg" /></Button>
                            </Col>
                        </Row>
                        <Row className="position-relative">
                            <Col style={{ minHeight: "220px" }}>
                                <div
                                    alt="bannière de décoration"
                                    className="rounded-lg w-100 h-100"
                                    style={{
                                        backgroundImage: "url('" + require("../../assets/img/default-banner.jpg").default + "')",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                            </Col>
                            <div className="rounded-lg position-absolute shadow bg-white" style={{ maxWidth: "250px", height: "130px", width: "100%", bottom: "-40px", left: "40px", overflow: "hidden" }}>
                                <div
                                    alt="logo de décoration"
                                    className="rounded-lg border-white w-100 h-100"
                                    style={{
                                        border: "2px solid",
                                        backgroundImage: "url('" + (miscConfig.defaultLogo ? miscConfig.defaultLogo : require("../../assets/img/default-thumbnail-profil.jpg").default) + "')",  // add pharmacy logo
                                        backgroundPosition: "center",
                                        backgroundSize: "cover"
                                    }}
                                ></div>

                            </div>
                        </Row>
                        <Row style={{ marginTop: "50px" }}>
                            <Col className="text-left text-primary h1">
                                <span>{condidatDetail.candidateName}</span>
                                <p>{condidatDetail.address}<br />{condidatDetail.city}</p>
                            </Col>
                            <Col className="text-right">
                                <Button
                                    outline
                                    color="primary"
                                    className="border-info w-100 p-2 shadow-none text-sm btn-sm"
                                    onClick={() => onDemandContact()}
                                >
                                    <i className="far fa-comment-dots" />
                                    <span> Prendre contact</span>
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-left">
                                <span className="text-gray">Détails du candidat</span>
                                <hr className="mt-2 mb-3" />
                            </Col>
                        </Row>

                        <Row className="h1">
                            <Col className="col-auto pl-0 pr-0">
                                <Badge className="bg-white text-primary px-3 text-lowercase">
                                    <span className="text-capitalize font-weight-light">Poste : </span>
                                    <span>{condidatDetail.position}</span>
                                </Badge>
                            </Col>
                            <Col className="col-auto pr-0">
                                <Badge color="secondary" className="px-3">
                                    <span className="text-capitalize font-weight-light">Type</span>
                                    <span className="font-weight-light text-lowercase"> de contrat : </span>
                                    <span>{condidatDetail.contractType}</span>
                                </Badge>
                            </Col>
                            {condidatDetail.info && (
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-gray px-3 text-lowercase">
                                        <span className="text-capitalize font-weight-light">Info : </span>
                                        <span>{condidatDetail.info}</span>
                                    </Badge>
                                </Col>
                            )}
                        </Row>

                        <Row className="mt-5">
                            <Col className="text-left">
                                <span className="text-gray">Horaires de l'offre</span>
                                <hr className="mt-2 mb-2" />
                            </Col>
                        </Row>

                        {condidatDetail.shiftsFlexibility && (
                            <p className="text-left" style={{ lineHeight: 1.2 }}>
                                Ce candidat indique qu'il est flexible sur les horaires
                            </p>
                        )}
                        <ShiftComparison
                            pharmacyShifts={"100000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"}
                            candidateShifts={condidatDetail.shifts}
                        />

                        {clickedCandidate.candidate.info && <>
                            <Row className="mt-3">
                                <Col className="text-left">
                                    <span className="text-gray">Informations suplémentaires</span>
                                    <hr className="mt-2 mb-2" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-left">
                                    {clickedCandidate.candidate.info}
                                </Col>
                            </Row></>
                        }

                        <Row>
                            <Col className="text-left">
                                <Button outline color="default" onClick={() => this.deactivateOverlay()} className="border-info"><i className="fas fa-angle-left pr-1 fa-lg text-primary" /> Retour</Button>
                            </Col>
                            <Button outline color="primary" className="border-info w-50 p-2 shadow-none text-sm btn-sm" onClick={() => onDemandContact()}><i className="far fa-comment-dots"></i><span>Prendre contact</span></Button>
                        </Row>

                    </div>
                </div>
            )}
        </div>
    );
};

export default MapCandidatesInRadiusWrapper;

import React, { Component } from 'react'
// import OfferService from '../services/offer.service'
import Map from './map/map.component'
import Table from './map/table.component'
import AccountService from '../services/account.service'
import { overlayStyle, informationStyle } from "../styles/mapPage.style"
import chatService from '../services/chat.service'
import ShiftComparison from './shift/shiftComparison.component'
import { Link } from 'react-router-dom'
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import miscConfig from './config/misc.config'
import accountConfig from './config/account.config'
export default class MapCandidatesInRadius extends Component {
    constructor(props) {
        super(props)
        this.retrieveCandidates = this.retrieveCandidates.bind(this)
        this.getRadius = this.getRadius.bind(this)
        this.setClickedCandidates = this.setClickedCandidates.bind(this)
        this.tableRef = null
        this.mapRef = null
        this.center = {
            lat: null,
            lng: null,
            writable: true
        }
        this.zoom = 6
        this.state = {
            radius: this.getRadius(this.zoom),
            user: AccountService.getCurrentUser(),
            content: "",
            activeMarker: null,
            offer: this.props.parentOffer,
            clickedCandidate: null,
            candidates: "",
            mapType: this.props.mapType ? this.props.mapType : "pharmacy"
        }
    }

    async retrieveCandidates() {
        var candidates
        candidates = await this.props.parentRetrieveCandidates(this.props.parentIdOffer)
        if (candidates) {
            await this.setState({ candidates })
        }
        var maxDistance = 1
        if (this.state.candidates) {
            this.state.candidates.forEach(candidate => {
                if (maxDistance < candidate.similarities.distance) {
                    maxDistance = candidate.similarities.distance
                }
                this.setState({ radius: maxDistance * 1000 })
                this.getZoom(this.state.radius)
            })
        }
    }
    getPharmacy() {
        this.center = {
            lat: parseFloat(this.props.pharmacy.latitude),
            lng: parseFloat(this.props.pharmacy.longitude),
        }
    }

    getZoom(radius) {
        var zoomLevel = this.zoom
        if (radius != null) {
            radius = radius + radius / 2
            var scale = radius / 1100
            zoomLevel = 16 - Math.log(scale) / Math.log(2);
        }
        return zoomLevel
    }
    getRadius(zoom) {
        var scale = Math.pow(10, (zoom - 16) / -3.321928)
        var radius = scale * 1100
        radius = radius * 2 / 3
        return radius
    }
    setActiveMarker = (childData) => {
        this.setState({ activeMarker: childData })
    }

    deactivateOverlay() {
        this.setState({ clickedCandidate: null })
    }
    setClickedCandidates(idCandidate) {
        var clickedCandidate = this.state.candidates.find(s => s.candidate.id === idCandidate)
        this.setState({ clickedCandidate })

    }

    onDemandContact = () => {
        chatService.initialize(this.state.clickedCandidate.candidate.id)
        this.props.history.push("/chats#" + this.state.clickedCandidate.candidate.id)
    }
    async refreshCandidates(candidates) {
        this.setState({ candidates })

    }
    async initialize() {
        this.getPharmacy()
        await this.retrieveCandidates()

    }

    async componentDidMount() {
        await this.initialize()
    }
    render() {

        const { clickedCandidate, mapType, radius, candidates } = this.state
        var softwares = []
        try {
            if (clickedCandidate?.candidate?.software) softwares = JSON.parse(clickedCandidate?.candidate?.software)
        } catch (e) {
            console.log(e)
        }
        return (
            <Row>
                {!this.props.offerListVisible && <Col className="mobile-list-button d-block d-md-none col-auto p-2 position-absolute " style={{ zIndex: '1090' }}>
                    <span className="fa-stack" onClick={this.props.toggleOfferList}>
                        <i className="fas fa-circle fa-stack-2x text-primary"></i>
                        <i className="fas fa-angle-left fa-stack-1x text-white"></i>
                    </span>
                </Col>}
                <Col className="col-12 p-0" >
                    {this.center.lat && this.center.lng && <Map ref={(instance) => { this.mapRef = instance }}
                        centerFromParent={this.center}
                        zoomFromParent={this.getZoom(radius)}
                        // radiusFromParent={this.getRadius(this.zoom)}
                        radiusFromParent={radius}
                        gestureHandling='none'
                        parentDisponibilities={this.props.parentCandidates}
                        setActiveMarker={this.setActiveMarker}
                        refreshOffers={this.refreshCandidates}
                        activeMarker={this.state.activeMarker}
                        setClickedMarker={this.setClickedCandidates}
                        mapType={mapType}
                    />}
                </Col>
                {mapType === "pharmacy" ? <>
                    <Col className="col-12 p-4 text-left" style={{ height: 'calc(100vh - calc(78px + min(30vh, 100vw)))', overflow: 'auto' }}>
                        <h1 className="text-secondary mb--1">Candidats potentiels pour votre offre d'emploi</h1>
                        <span className="text-default text-sm"><i className="fas fa-info-circle" /> Sélectionné pour vous en fonction de <a href="/profile">vos critères</a></span>

                        <Table ref={(instance) => { this.tableRef = instance }}
                            parentDisponibilities={this.props.parentCandidates}
                            setActiveMarker={this.setActiveMarker}
                            activeMarker={this.state.activeMarker}
                            setClickedMarker={this.setClickedCandidates}
                            mapType={'pharmacy'}
                        />
                    </Col>
                </>
                    : <Card className="position-fixed opacity-8 w-100" style={{ zIndex: '1080', top: '35px' }}>
                        <CardHeader>
                            <h1 className="text-secondary">Candidats potentiels pour votre offre d'emploi</h1>
                            <span className="text-default text-sm"><i className="fas fa-info-circle" /> Sélectionné pour vous en fonction de <a href="/profile">vos critères</a></span>
                        </CardHeader>
                        <CardBody className="mx-auto" style={{ maxWidth: '700px' }}>
                            Nous avons trouvé {candidates.length > 2 ? 'au moins ' + candidates.length : 'de nombreux'} candidats recherchant une pharmacie dans votre zone.
                            Pour les découvrir, <Link to="/subscription">commencez gratuitement dès maintenant en cliquant ici</Link>.
                            Pour avoir une liste de candidats correspondant mieux à votre recherche, nous vous invitons également à revenir sur cette page une fois abonnée et à créer une offre.
                        </CardBody>
                    </Card>
                }
                {clickedCandidate && <div>
                    <div className="overlay" id="overlay" style={overlayStyle} onClick={() => this.deactivateOverlay()} />
                    <div className="information p-3 p-md-5" id="informationPage" style={informationStyle}>
                        <Row>
                            <Col className="text-right">
                                <Button outline color="primary" onClick={() => this.deactivateOverlay()} className="mb--4 mr--4 bg-white" style={{ zIndex: "1080" }}><i className="fas fa-times fa-lg" /></Button>
                            </Col>
                        </Row>
                        <Row className="position-relative">
                            <Col style={{ minHeight: "220px" }}>
                                <div
                                    alt="bannière de décoration"
                                    className="rounded-lg w-100 h-100"
                                    style={{
                                        backgroundImage: "url('" + require("../assets/img/default-banner.jpg").default + "')",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                            </Col>
                            <div className="rounded-lg position-absolute shadow bg-white" style={{ maxWidth: "250px", height: "130px", width: "100%", bottom: "-40px", left: "40px", overflow: "hidden" }}>
                                <div
                                    alt="logo de décoration"
                                    className="rounded-lg border-white w-100 h-100"
                                    style={{
                                        border: "2px solid",
                                        backgroundImage: "url('" + (miscConfig.defaultLogo ? miscConfig.defaultLogo : require("../assets/img/default-thumbnail-profil.jpg").default) + "')",  // add pharmacy logo
                                        backgroundPosition: "center",
                                        backgroundSize: "cover"
                                    }}
                                ></div>

                            </div>
                        </Row>
                        <Row style={{ marginTop: "50px" }}>
                            <Col className="text-left text-primary h1">
                                <span>{this.state.clickedCandidate.candidate.name}</span>
                            </Col>
                            <Col className="text-right">
                                <Button outline color="primary"
                                    className="border-info w-100 p-2 shadow-none text-sm btn-sm" onClick={() => this.onDemandContact()}><i className="far fa-comment-dots"></i><span>Prendre contact</span></Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-left">
                                <span className="text-gray">Détails du candidat</span>
                                <hr className="mt-2 mb-3" />
                            </Col>
                        </Row>
                        <Row className="h1">
                            <Col className="col-auto pl-0 pr-0">
                                <Badge className="bg-white text-primary px-3 text-lowercase"><span className="text-capitalize font-weight-light">Poste : </span><span>{accountConfig.positionList[this.state.clickedCandidate.candidate.disponibility.position]}</span></Badge>
                            </Col>
                            <Col className="col-auto pr-0">
                                <Badge className="px-3 bg-primary text-lowercase"><span className="text-capitalize font-weight-light">Distance : </span><i className="fas fa-map-marker-alt" /><span className="pl-1">{this.state.clickedCandidate.similarities.distance} km</span></Badge>
                            </Col>
                            <Col className="col-auto pr-0">
                                <Badge color="secondary" className="px-3"><span className="text-capitalize font-weight-light">Type</span><span className="font-weight-light text-lowercase"> de contrat : </span><span>{this.state.clickedCandidate.candidate.disponibility.contract_type}</span></Badge>
                            </Col>
                            <Col className="col-auto pr-0">
                                <Badge className="bg-gray px-3 text-lowercase"><span className="text-capitalize font-weight-light">Rémunération : </span><span>{this.state.clickedCandidate.candidate.disponibility.salary} € / h</span></Badge>
                            </Col>
                            <Col className="col-auto pr-0">
                                <Badge className="bg-light text-gray-dark">
                                    <span className="text-capitalize font-weight-light">LGO</span><span className="font-weight-light text-lowercase"> connus :</span>{softwares.map((software) => (
                                        <span key={software}>
                                            &nbsp;{software}&nbsp;
                                        </span>
                                    ))}
                                </Badge>
                            </Col>
                            {this.state.clickedCandidate.candidate.cv_validated &&
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-default px-3 text-lowercase"><i className="fas fa-user-check" /><span className="pl-2 text-capitalize font-weight-light">CV vérifié</span></Badge>
                                </Col>
                            }
                            {this.state.clickedCandidate.candidate.diploma_validated &&
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-default px-3 text-lowercase"><i className="fas fa-clipboard-check" /><span className="pl-2 text-capitalize font-weight-light">Diplôme vérifié</span></Badge>
                                </Col>
                            }
                            {this.state.clickedCandidate.candidate.kbis_validated &&
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-default px-3 text-lowercase"><i className="fas fa-building" /><span className="pl-2 text-capitalize font-weight-light">Kbis vérifié</span></Badge>
                                </Col>
                            }
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-left">
                                <span className="text-gray">Horaires de l'offre</span>
                                <hr className="mt-2 mb-2" />
                            </Col>
                        </Row>

                        {this.state.clickedCandidate.candidate.disponibility.shifts_flexibility && <p className="text-left" style={{ lineHeight: 1.2 }}>Ce candidat indique qu'il est flexible sur les horaires</p>}
                        <ShiftComparison
                            pharmacyShifts={this.props.parentShifts}
                            candidateShifts={this.state.clickedCandidate.candidate.disponibility.shifts}
                        />
                        {this.state.clickedCandidate.candidate.info && <>
                            <Row className="mt-3">
                                <Col className="text-left">
                                    <span className="text-gray">Informations suplémentaires</span>
                                    <hr className="mt-2 mb-2" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-left">
                                    {this.state.clickedCandidate.candidate.info}
                                </Col>
                            </Row></>
                        }
                        <Button outline color="primary" className="border-info w-100 p-2 shadow-none text-sm btn-sm" onClick={() => this.onDemandContact()}><i className="far fa-comment-dots"></i><span>Prendre contact ff</span></Button>
                        <Row>
                            <Col className="text-left">
                                <Button outline color="default" onClick={() => this.deactivateOverlay()} className="border-info"><i className="fas fa-angle-left pr-1 fa-lg text-primary" /> Retour</Button>
                            </Col>
                        </Row>

                    </div>
                </div>}
            </Row>
            //Add the map here!
        )
    }
}
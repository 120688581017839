import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Badge, Button } from "reactstrap";
import ShiftComparison from "../shift/shiftComparison.component";
import miscConfig from '../config/misc.config'
import OfferDataService from "./../../services/offer.service"
import chatService from "../../services/chat.service";

const OfferDisplayWrapper = (props) => {
    const { id } = useParams();

    const [offerDetails, setOfferDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch offer details from API
    useEffect(() => {
        const fetchOfferDetails = async () => {
            try {
                setLoading(true);
                let response = await OfferDataService.getOneOffer(id);
                const data = response;
                setOfferDetails(data);
            } catch (error) {
                console.error("Error fetching offer details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOfferDetails();
    }, [id]);

    useEffect(() => {
        if (offerDetails?.offer?.pharmacy?.name) {
            setTimeout(() => {
                document.title = `Offre details - ${offerDetails.offer.pharmacy.name}`;
            }, 500);
        }
    }, [offerDetails]);

    if (loading) {
        return <div style={{ margin: "40px", padding: "0px" }}>Chargement...</div>;
    }

    if (!offerDetails) {
        return <div style={{ margin: "40px", padding: "0px" }}>Aucune offre trouvée.</div>;
    }

    const onDemandContact = () => {
        chatService.initialize(offerDetails.offer.pharmacy_id)
        props.history.push("/chats#" + offerDetails.offer.pharmacy_id)
    }

    return (
        <div style={{ margin: "10px", padding: "0px" }}>
            {(
                <div>
                    <div
                        className="overlay"
                        style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background: "rgba(0,0,0,0.5)" }}
                    />
                    <div
                        className="information p-3 p-md-5"
                        style={{ position: "relative", zIndex: 1050, background: "white", borderRadius: "8px" }}
                    >
                        <Row>
                            <Col className="text-right">
                                <Button outline color="primary" onClick={() => this.deactivateOverlay()} className="mb--4 mr--4 bg-white" style={{ zIndex: "1080" }}><i className="fas fa-times fa-lg" /></Button>
                            </Col>
                        </Row>
                        <Row className="position-relative">
                            <Col style={{ minHeight: "220px" }}>
                                <div
                                    alt="bannière de décoration"
                                    className="rounded-lg w-100 h-100"
                                    style={{
                                        backgroundImage: "url('" + require("../../assets/img/default-banner.jpg").default + "')",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                            </Col>
                            <div className="rounded-lg position-absolute shadow bg-white" style={{ maxWidth: "250px", height: "130px", width: "100%", bottom: "-40px", left: "40px", overflow: "hidden" }}>
                                <div
                                    alt="logo de décoration"
                                    className="rounded-lg border-white w-100 h-100"
                                    style={{
                                        border: "2px solid",
                                        backgroundImage: "url('" + (!miscConfig.defaultLogo ? miscConfig.defaultLogo : require("../../assets/img/default-thumbnail-profil.jpg").default) + "')",  // add pharmacy logo
                                        backgroundPosition: "center",
                                        backgroundSize: "cover"
                                    }}
                                ></div>

                            </div>
                        </Row>

                        <Row style={{ marginTop: "50px" }} className="mb-3">
                            <Col className="col-12 col-lg-8 text-left">
                                <span className="text-primary h1">{offerDetails.offer.pharmacy.name}</span>
                                <p>{offerDetails.offer.pharmacy.address}<br />{offerDetails.offer.pharmacy.city}-{offerDetails.offer.pharmacy.zip}</p>
                            </Col>
                            <Col className="col-12 col-lg-4 text-right">
                                <Button onClick={() => onDemandContact()} outline color="primary" className="border-info w-100 p-2 shadow-none text-sm btn-sm"><i className="far fa-comment-dots"></i><span>Prendre contact</span></Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-left">
                                <span className="text-gray">Détails de l'offre</span>
                                <hr className="mt-2 mb-3" />
                            </Col>
                        </Row>

                        <Row className="h1">
                            <Col className="col-auto pl-0 pr-0">
                                <Badge className="bg-white text-primary px-3 text-lowercase">
                                    <span className="text-capitalize font-weight-light">Poste : </span>
                                    <span>{offerDetails.offer.disponibility.position}</span>
                                </Badge>
                            </Col>
                            <Col className="col-auto pr-0">
                                <Badge className="px-3 bg-primary text-lowercase">
                                    <span className="text-capitalize font-weight-light">Distance : </span>
                                    <i className="fas fa-map-marker-alt" />
                                    <span className="pl-1">{offerDetails.similarities.distance} km</span>
                                </Badge>
                            </Col>
                            <Col className="col-auto pr-0">
                                <Badge color="secondary" className="px-3">
                                    <span className="text-capitalize font-weight-light">Type</span>
                                    <span className="font-weight-light text-lowercase"> de contrat : </span>
                                    <span>{offerDetails.offer.disponibility.contract_type}</span>
                                </Badge>
                            </Col>
                            {offerDetails.offer.disponibility.info && (
                                <Col className="col-auto pr-0">
                                    <Badge className="bg-gray px-3 text-lowercase">
                                        <span className="text-capitalize font-weight-light">Info : </span>
                                        <span>{offerDetails.offer.disponibility.info}</span>
                                    </Badge>
                                </Col>
                            )}

                            <Col className="col-12 col-lg-auto pr-0">
                                <Badge className="bg-gray px-3 text-lowercase"><span className="text-capitalize font-weight-light">Rémunération : </span><span>{offerDetails.offer.disponibility.salary} € / h</span></Badge>
                            </Col>
                            {offerDetails.offer.pharmacy.software && (
                                <Col className="col-12 col-lg-auto pr-0">
                                    <Badge className="bg-light text-gray-dark">
                                        <span className="text-capitalize font-weight-light">LGO : </span>{offerDetails.offer.pharmacy.software}
                                    </Badge>
                                </Col>
                            )}
                        </Row>

                        <Row className="mt-5">
                            <Col className="text-left">
                                <span className="text-gray">Horaires de l'offre</span>
                                <hr className="mt-2 mb-2" />
                            </Col>
                        </Row>

                        {offerDetails.offer.disponibility.shifts_flexibility && (
                            <p className="text-left" style={{ lineHeight: 1.2 }}>
                                Ce poste est disponible pour les horaires flexibles.
                            </p>
                        )}
                        <ShiftComparison
                            pharmacyShifts={offerDetails.offer.disponibility.shifts}
                            candidateShifts={"100000000000000000011110000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"}
                        />

                        {/* {offerDetails.offer.info && <>
                            <Row className="mt-3">
                                <Col className="text-left">
                                    <span className="text-gray">Informations suplémentaires</span>
                                    <hr className="mt-2 mb-2" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-left">
                                    {offerDetails.offer.info}
                                </Col>
                            </Row></>
                        } */}

                        <Row>
                            <Col className="text-left">
                                <Button outline color="default" onClick={() => this.deactivateOverlay()} className="border-info"><i className="fas fa-angle-left pr-1 fa-lg text-primary" /> Retour</Button>
                            </Col>
                            <Button outline color="primary" className="border-info w-50 p-2 shadow-none text-sm btn-sm" onClick={() => onDemandContact()}><i className="far fa-comment-dots"></i><span>Prendre contact</span></Button>
                        </Row>

                    </div>
                </div>
            )}
        </div>
    );
};

export default OfferDisplayWrapper;

import http from '../http-common'
import accountHeader from './account-header'

class OfferService {

    getOffersInRadius(lat, lon, rad) {
        return http.get(`/offers/${lat}/${lon}/${rad}`, { headers: accountHeader() }).then(response=>{
            return response.data
        })
    }
    getAllOffers() {
        return http.get(`/offers`, { headers: accountHeader() }).then(response=>{
            return response.data
        })
    }
    getOffersByPharmacy(idPharmacy) {
        return http.get(`/offers?pharmacy_id=${idPharmacy}`, { headers: accountHeader() }).then(response=>{
            return response.data
        })
    }
    getOneOffer(idOffer) {
        return http.get(`/offers/${idOffer}`, { headers: accountHeader() }).then(response=>{
            return response.data
        })
    }
    getOneOfferDetail(idOffer) {
        return http.get(`/offers/details/${idOffer}`, { headers: accountHeader() }).then(response=>{
            return response.data
        })
    }
    demandValidation(idOffer) {
        return http.post(`/offers/validation/demand/${idOffer}`, {}, { headers: accountHeader() })
    }
    editOffer(idOffer, body) {
        return http.post(`/offers/${idOffer}`, body, { headers: accountHeader() })
    }
    createOffer(body) {
        return http.post(`/offers`, body, { headers: accountHeader() })
    }
    archiveOffer(idOffer, reason) {
        return http.post(`/offers/archive/${idOffer}`, { reason: reason }, { headers: accountHeader() }).catch((err) => { return alert("Une erreur est survenu lors de l'archivage de l'offre") })
    }
    findSignedOffers() {
        return http.get(`/offers/signed`, { headers: accountHeader() }).then(response=>{
            return response.data
        }).catch((err) => {
            console.log(err)
        })
    }
}

export default new OfferService()
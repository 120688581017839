const gaScript = document.createElement("script");
gaScript.async = true;
gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_MEASUREMENT_ID}`;
document.head.appendChild(gaScript);

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

gtag("js", new Date());
gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID, {
  page_path: window.location.pathname + window.location.search,
});

// Function to track UTM parameters
const trackUTMParams = () => {
  const params = new URLSearchParams(window.location.search);
  const utmData = {
    utm_source: params.get("utm_source"),
    utm_campaign: params.get("utm_campaign"),
    utm_content: params.get("utm_content"),
  };

  if (utmData.utm_source) {
    // Store UTM parameters to persist across pages
    localStorage.setItem("utm_params", JSON.stringify(utmData));

    // Send UTM event to Google Analytics
    gtag("event", "utm_tracking", {
      event_category: "Marketing",
      event_label: `${utmData.utm_source} - ${utmData.utm_campaign}`,
      ...utmData,
    });

    console.log("UTM Tracking Sent:", utmData);
  }
};

// Call the function when the app loads
trackUTMParams();

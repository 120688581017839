import React, { Component } from "react"
import { Switch, Route, Redirect, Link } from "react-router-dom"
import './App.css'
import './assets/css/meetPharma.css'
import { AuthenticatedRoute, AuthenticatedCandidateRoute, AuthenticatedPharmacyRoute, UnauthenticatedRoute, AuthenticatedAdminRoute } from "./components/secureRoute.component"


import AccountService from "./services/account.service"
import candidateService from "./services/candidate.service"
import pharmacyService from "./services/pharmacy.service"

import Login from './components/login.component'
import MapOffersInRadius from './components/mapOffersInRadius.component'
import Home from './components/home.component'
import Reset from './components/reset.component'
import ChatList from './components/chatList.component'
import OffersList from './components/offersList.component'
import OffersListAdmin from './components/offersListAdmin.component'
import EditProfile from './components/profileEdit.component'
import Toolbox from './components/toolbox.component'
import socketClient from "socket.io-client"
import ReactGA from 'react-ga'
import CookieConsent from "react-cookie-consent";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavItem,
  Button,
  Badge,
  NavLink,
  UncontrolledAlert
} from "reactstrap"

import { ReactComponent as MagnifierIcon } from './assets/img/icons/magnifier.svg';
import FileValidation from "./components/admin/fileValidation.component"
import Candidats from "./components/admin/candidats.component"
import Pharmacies from "./components/admin/pharmacies.component"
import Stats from "./components/stats.component"
// import Subscription from "./components/subscription.component"
import AdminHome from "./components/admin/adminHome.component"
import AdminPoints from "./components/admin/adminPoints.component"
import MeetCodes from "./components/admin/meetCodes.component"
import Cagnotte from "./components/cagnotte.component"
import Missions from "./components/missions.component"
import CGU from "./components/tools/cgu.component"
import RGPD from "./components/tools/rgpd.component"
import CGV from "./components/tools/cgv.component"
import { displayNotification, requestNotificationPermission } from "./serviceWorkerRegistration"
import { extractTextFromHTML } from "./utils/formattingText"
import PricingTable from "./components/pricingTable.component"
import { isIOS, isSafari } from "react-device-detect"
import Prompt from "./components/prompt.component"
import stripeService from "./services/stripe.service"
import MapCandidatesInRadiusWrapper from "./components/wrapper/MapCandidatesInRadiusWrapper.component"
import OfferDisplayWrapper from "./components/wrapper/OfferDisplayWrapper.component"

const SERVER = process.env.REACT_APP_SERVER

ReactGA.initialize('G-Y2CMSVV3S7')

class App extends Component {
  constructor(props) {
    super(props)
    this.updateNotifications = this.updateNotifications.bind(this)
    const currentUser = AccountService.getCurrentUser()
    this.socket = null
    this.isOldTitle = true
    this.title = "Meet Pharma"
    this.notificationTitle = " nouveau(x) message(s)"
    this.blink = false
    this.interval = null

    this.state = {
      isCandidate: currentUser && currentUser.role === "candidat",
      isPharmacy: currentUser && currentUser.role === "titulaire",
      isAdmin: currentUser && currentUser.role === "admin",
      collapsed: true,
      chatList: [],
      user: currentUser,
      loading: true
    }
  }

  toggleNavbar = () => this.setState({ collapsed: !this.state.collapsed });

  async setData() {
    if (this.state.isCandidate) {
      var candidate = await candidateService.getCandidateByAccount(this.state.user.id)
      this.setState({ candidate, myName: candidate.candidateName, loading: false })
    } else if (this.state.isPharmacy) {
      var pharmacy = await pharmacyService.getPharmacyByAccount(this.state.user.id)
      this.setState({ pharmacy, myName: pharmacy.pharmacyName, loading: false })
    }
  }

  async updateNotifications(chatList) {
    this.setState({ chatList })
  }
  logOut() {
    AccountService.logout()
  }

  async handleSubscriptionStatus() {
    const subscriptionStatus = await stripeService.checkSubscriptionStatus(
      this.state.user.email
    );

    if (!subscriptionStatus.free && !subscriptionStatus.subscribed && !subscriptionStatus.trialEnd) {
      // this.logOut();
      // this.props.history.push("/login");
      // window.location.reload();
    }
  }


  async componentDidMount() {
    if (this.state.user && !this.state.isAdmin) {
      const subscriptionPromise = this.handleSubscriptionStatus();
      const notificationPermissionPromise = !(isIOS || isSafari)
        ? requestNotificationPermission()
        : Promise.resolve();
      const setDataPromise = this.setData();
      await Promise.all([subscriptionPromise, notificationPermissionPromise, setDataPromise]);

      if (!(isIOS || isSafari)) requestNotificationPermission();
      await this.setData()
      var socket = socketClient(SERVER);
      //emit login
      socket.emit('login', this.state.user)
      socket.on('logged', async (chatList) => {
        this.updateNotifications(chatList)
      })
      this.socket = socket
      socket.on('confirm read', (info) => {
        var newChatList = this.state.chatList
        for (var i = 0; i < newChatList.length; i++) {
          if (newChatList[i].id !== info.chatId) {
            continue
          }
          newChatList[i].messages.forEach(message => {
            if (message.sender_name !== this.state.myName && this.state.user.id === info.readerId) {
              message.read = true
            }
          });
        }
        this.setState({ chatList: newChatList })
      })
      socket.on("chat received", ({ msg }) => {
        // Add new message to the list/ replace the oldest one
        var newChatList = this.state.chatList
        for (var i = 0; i < newChatList.length; i++) {
          if (newChatList[i].id !== msg.chatRoomId) {
            continue
          }
          newChatList[i].messages.push({
            "sender_name": msg.senderName,
            "text": msg.text,
            "time": msg.time,
            "read": msg.senderName === this.state.myName,
            "chat_id": msg.chatRoomId
          });
        }
        this.setState({ chatList: newChatList })
      })
      socket.on('heartbeat', () => {
        socket.emit('heartbeat-response', { beat: 1 });
      });

      socket.on('disconnect', (reason) => {
        console.log('Disconnected from server', reason);
        this.setState({ isConnected: false, isReconnecting: true });
      });

      socket.on('connect', () => {
        console.log('Connected to server');
        socket.emit('login', this.state.user);
      });
      socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
      socket.on('reconnect_failed', () => {
        console.log('Unable to reconnect to the server');
      });
      socket.on('reconnect', (attemptNumber) => {
        console.log('Reconnected to server');
      });

    } else {
      this.setState({ loading: false })
    }
  }

  unflash() {
    if (!this.flashing) return

    clearTimeout(this.flashing)
    document.title = this.title

    this.flashing = null
  }

  flashDocumentTitle(oldTitle, newTitle) {
    this.unflash()
    // if (!document.hidden) return


    var i = 0
    function flash() {
      //if (!document.hidden) return this.unflash()

      document.title = i ? newTitle : oldTitle
      i = (i + 1) % 2
    }

    flash()
    this.flashing = setInterval(flash, 1000)
  }

  renderNavItems = (user, isCandidate, isPharmacy, isAdmin, countUnread, btnClasses = '', mainBtnClasses = '', is_paid = false, mobile = true) =>
    <>
      {user && !mobile &&
        <NavItem className={mainBtnClasses}>
          <NavLink href={"/"}>
            <Button outline color="primary" size="sm" className="border-info p-2 px-3 shadow-none ml--4 text-sm"><i className="fas fa-home" /></Button>
          </NavLink>
        </NavItem>
      }
      {isCandidate && (
        <>
          <NavItem className={btnClasses + " text-left"} style={{ maxWidth: "50vw" }}>
            <NavLink href={"/offersInRadius"} className="px-1">
              {mobile ? <>
                <Button color="primary" className="px-2 py-1 w-100" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                  <span style={{ height: "28px" }}><MagnifierIcon className="mr-2" fill="white" style={{ height: 'auto', width: "28px" }} />{this.state.user.city}</span>
                </Button>
              </> :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="fas fa-search-location" />
                  <span>Rechercher une offre</span>
                </Button>
              }
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses + (mobile ? " col-auto border-right border-light" : "")}>
            <NavLink href={"/chats"} className="text-default px-1">
              {mobile ?
                <span><i className="fas fa-comments mt-1" style={{ fontSize: "1.7em" }} />{countUnread > 0 && <Badge pill className="bg-primary ml--1 p-1 px-2">
                  {countUnread}
                </Badge>}</span>
                :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="far fa-comments" />
                  <span>Messages</span>
                  {countUnread > 0 && <Badge pill className="bg-primary p-1 px-2">
                    {countUnread}
                  </Badge>}
                </Button>
              }
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses + (mobile ? " col-auto border-right border-light" : "")}>
            <NavLink href={"https://meetpharma-job.fr"} className="text-default px-1">
              {mobile ?
                <i className="fas fa-layer-group mt-1" style={{ fontSize: "1.7em" }} />
                :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="fas fa-layer-group" /><span>Qui sommes nous</span></Button>
              }
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses + (mobile ? " col-auto" : "")}>
            <NavLink href={"/profile"} className="text-primary px-1">
              {mobile ?
                <i className="far fa-user-circle mt-1" style={{ fontSize: "1.9em" }} />
                :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="far fa-user" />
                  <span>Mon profil</span>
                </Button>
              }
            </NavLink>
          </NavItem>
        </>
      )}

      {isPharmacy &&  /* is_paid && */(
        <>
          <NavItem className={btnClasses + " text-left"}>
            <NavLink href={"/myOffers"} className="px-1" style={{ height: '100%' }}>
              {mobile ? <>
                <Button color="primary" className="px-2 py-1" style={{ height: '100%' }}>
                  <i className="far fa-list-alt fa-lg" />
                  <span className="">Mes offres</span>
                </Button>
              </> :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="far fa-list-alt" />
                  <span>Mes offres</span>
                </Button>
              }
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses + (mobile ? " col-auto border-right border-light" : "")}>
            <NavLink href={"/chats"} className="text-default px-1">
              {mobile ?
                <span><i className="fas fa-comments mt-1" style={{ fontSize: "1.7em" }} />{countUnread > 0 && <Badge pill className="bg-primary ml--1 p-1 px-2">
                  {countUnread}
                </Badge>}</span>
                :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="far fa-comments" />
                  <span>Messages</span>
                  {countUnread > 0 && <Badge pill className="bg-primary p-1 px-2">
                    {countUnread}
                  </Badge>}
                </Button>
              }
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses + (mobile ? " col-auto border-right border-light" : "")}>
            <NavLink href={"https://meetpharma-job.fr"} className="text-default px-1">
              {mobile ?
                <i className="fas fa-layer-group mt-1" style={{ fontSize: "1.7em" }} />
                :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="fas fa-layer-group" /><span>Qui sommes nous</span></Button>
              }
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses + (mobile ? " col-auto" : "")}>
            <NavLink href={"/profile"} className="text-primary px-1">
              {mobile ?
                <i className="far fa-user-circle mt-1" style={{ fontSize: "1.9em" }} />
                :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="far fa-user" />
                  <span>Mon profil</span>
                </Button>
              }
            </NavLink>
          </NavItem>
        </>
      )}
      {/* {isPharmacy && !is_paid && (
        <>
          <NavItem className={btnClasses}>
            <NavLink>
              <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm" disabled ><i className="far fa-list-alt" />
                {!mobile && <span>Mes offres</span>}
              </Button>
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses}>
            <NavLink>
              <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm" disabled ><i className="far fa-comments" />
                {!mobile && <span>Discussions</span>}
                {countUnread > 0 && <Badge pill className="bg-primary p-1 px-2">
                  {countUnread}
                </Badge>}</Button>
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses}>
            <NavLink>
              <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm" disabled><i className="fas fa-toolbox" />
                {!mobile && <span>Boite à outils</span>}
              </Button>
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses + (mobile ? " col-auto" : "")}>
            <NavLink href={"/profile"} className="text-primary px-1">
              {mobile ?
                <i className="far fa-user-circle mt-1" style={{ fontSize: "1.9em" }} />
                :
                <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="far fa-user" />
                  <span>Mon profil</span>
                </Button>
              }
            </NavLink>
          </NavItem>
        </>
      )} */}
      {isAdmin &&
        <>
          <NavItem className={btnClasses}>
            <NavLink href={"/certification"}>
              <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="fas fa-clipboard-check" />
                {!mobile && <span>Documents</span>}

              </Button>
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses}>
            <NavLink href={"/gestionCagnotte"}>
              <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="fas fa-money-check-alt" />
                {!mobile && <span>Décagnottage</span>}
              </Button>
            </NavLink>
          </NavItem>
          <NavItem className={btnClasses}>
            <NavLink href={"/meetcodes"}>
              <Button outline color="default" size="sm" className="border-info w-100 p-2 shadow-none text-sm"><i className="fas fa-tags" />
                {!mobile && <span>MeetCodes</span>}
              </Button>
            </NavLink>
          </NavItem>
        </>
      }
      {
        user ? (
          <NavItem className={mainBtnClasses} >
            <NavLink href={"/login"} onClick={this.logOut} className="text-primary px-1 px-md-2">
              <Button outline color="primary" size="sm" className="border-primary w-100 py-2 px-3 shadow-none text-sm"><i className="fas fa-power-off" /></Button>
            </NavLink>
          </NavItem>
        ) : (
          <NavItem>
            <NavLink className={mainBtnClasses} href={"/login"}>
              <Button color="white" size="sm" className="d-block d-lg-none w-100 px-2 py-1 shadow-none text-xs text-primary"><i className="far fa-user" /><span>Connexion</span>
              </Button>
              <Button color="white" size="sm" className="d-none d-lg-block w-100 px-2 py-1 shadow-none text-sm text-primary"><i className="far fa-user" /><span>Connexion</span>
              </Button>
            </NavLink>
          </NavItem>
        )
      }
    </>

  render() {
    if (this.state.loading) return <></>
    document.title = this.title
    const { user, isCandidate, isPharmacy, pharmacy, isAdmin } = this.state
    const altNav = this.props.location.pathname === '/' && (!user)
    var countUnread = 0
    // Check if the chatlist exists and is not empty to prevent errors
    if (this.state.chatList && this.state.chatList.length > 0) {
      this.state.chatList.forEach(chat => {
        if ((chat.initiator === this.state.myName && chat.messages.length === 2 && !chat.messages[chat.messages.length - 1].read && chat.messages[chat.messages.length - 1].sender_name !== '*system*') ||
          (chat.initiator !== this.state.myName && chat.messages.length === 1 && !chat.messages[chat.messages.length - 1].read && chat.messages[chat.messages.length - 1].sender_name !== '*system*') ||
          (chat.messages.length > 1 &&
            !chat.messages[chat.messages.length - 1].read &&
            chat.messages[chat.messages.length - 1].sender_name !== this.state.myName &&
            chat.messages[chat.messages.length - 1].sender_name !== '*system*'
          )) {
          countUnread++
        }
        if (countUnread > 0) {
          this.flashDocumentTitle(this.title, countUnread + this.notificationTitle)
        } else {
          this.unflash()
        }
      })
    }
    return (

      <div className="App" style={['/', '/login', '/pricingTable'].includes(this.props.location.pathname) ? {
        minHeight: '100%',
        backgroundImage: "url('" + require("./assets/img/background-homepage.jpg").default + "')",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundSize: "cover"
      } : {}}>
        {altNav ?
          <Navbar className="navbar-expand p-2 px-2 sticky-top justify-content-between" style={{ background: "rgba(65,3,52,.4)" }}>
            <NavbarBrand href="/" className="my-auto ml-sm-0 mr-sm-auto">
              <img
                alt="Meetpharma Logo"
                className="navbar-brand-img-big"
                src={require("./assets/img/logo-meetpharma-white.png").default} />
            </NavbarBrand>
            <Nav>
              {this.renderNavItems(user, isCandidate, isPharmacy, isAdmin, countUnread, 'd-none d-lg-flex', '', pharmacy?.is_paid, false)}
            </Nav>
          </Navbar> :
          <Navbar className="navbar-expand bg-white shadow-lg p-2 px-4 sticky-top">
            <NavbarBrand href="/" className="mr-auto"><img
              alt="Meetpharma Logo"
              className="navbar-brand-img"
              src={require("./assets/img/logo-meetpharma.png").default} />
            </NavbarBrand>
            <Nav>
              {this.renderNavItems(user, isCandidate, isPharmacy, isAdmin, countUnread, 'd-none d-lg-flex', '', pharmacy?.is_paid, false)}
            </Nav>
          </Navbar>
        }
        {this.state.user && (isIOS || isSafari) && (
          <Prompt />
        )}
        <div className="container-fluid">
          {isCandidate && this.state.candidate?.standby_mode && <UncontrolledAlert
            color="default"
            className="position-fixed"
            style={{ zIndex: 9000, top: '85px', right: '0', margin: '0 10px', lineHeight: '1.1' }}
          >
            <i className="fa fa-exclamation-triangle" /> Votre <Link to="/profile">profil</Link> n'apparait pas dans les résultats de recherche
          </UncontrolledAlert >
          }
          <Switch>
            <Route exact path={["/"]} >
              {this.state.user ?
                <>
                  {/* {pharmacy?.is_paid || */(this.state.user && (this.state.user.role === "candidat" || this.state.user.role === "titulaire")) ? <Stats {...this.props} {...this.state} /> : <>
                    {this.state.user && this.state.user.role === "admin" ?
                      <AdminHome {...this.props} {...this.state} /> : <>
                        <OffersList {...this.props} {...this.state} /></>}
                  </>
                  }
                </> : < Home />
              }
            </Route>
            {/* component={Home} /> */}

            <AuthenticatedRoute exact path="/pricingTable" {...this.state}><PricingTable {...this.props} {...this.state} /></AuthenticatedRoute>
            <UnauthenticatedRoute exact path="/login" {...this.state}><Login {...this.props} {...this.state} /></UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/cgu" {...this.state}><CGU {...this.props} {...this.state} /></UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/cgv" {...this.state}><CGV {...this.props} {...this.state} /></UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/RGPD" {...this.state}><RGPD {...this.props} {...this.state} /></UnauthenticatedRoute>

            <AuthenticatedRoute exact path="/chats" {...this.state}><ChatList {...this.props} {...this.state} /></AuthenticatedRoute>
            <AuthenticatedRoute path="/profile" {...this.state}><EditProfile {...this.props} {...this.state} /></AuthenticatedRoute>
            <AuthenticatedRoute path="/tools" {...this.state}><Toolbox {...this.props} {...this.state} /></AuthenticatedRoute>

            <AuthenticatedAdminRoute exact path="/candidats" {...this.state}><Candidats {...this.props} {...this.state} /></AuthenticatedAdminRoute>
            <AuthenticatedAdminRoute exact path="/pharmacies" {...this.state}><Pharmacies {...this.props} {...this.state} /></AuthenticatedAdminRoute>
            <AuthenticatedAdminRoute exact path="/certification" {...this.state}><FileValidation {...this.props} {...this.state} /></AuthenticatedAdminRoute>
            <AuthenticatedAdminRoute exact path="/gestionCagnotte" {...this.state}><AdminPoints {...this.props} {...this.state} /></AuthenticatedAdminRoute>
            <AuthenticatedAdminRoute exact path="/meetcodes" {...this.state}><MeetCodes {...this.props} {...this.state} /></AuthenticatedAdminRoute>
            <AuthenticatedAdminRoute path="/offers/:pharmacyAccountId" {...this.state}><OffersListAdmin {...this.props} {...this.state} /></AuthenticatedAdminRoute>

            <AuthenticatedCandidateRoute exact path="/missions" {...this.state}><Missions {...this.props} {...this.state} /></AuthenticatedCandidateRoute>
            <AuthenticatedCandidateRoute exact path="/cagnotte" {...this.state}><Cagnotte {...this.props} {...this.state} /></AuthenticatedCandidateRoute>
            <AuthenticatedCandidateRoute exact path="/offersInRadius" {...this.state}><MapOffersInRadius {...this.props} {...this.state} /></AuthenticatedCandidateRoute>

            <AuthenticatedPharmacyRoute exact path="/myOffers" {...this.state}><OffersList {...this.props} {...this.state} /></AuthenticatedPharmacyRoute>
            {/* <AuthenticatedPharmacyRoute exact path="/subscription" {...this.state}><Subscription {...this.props} {...this.state} /></AuthenticatedPharmacyRoute> */}

            <Route path="/reset/:resetCode" ><Reset  {...this.props} {...this.state} /></Route>
            <Route path="/activate/:activationCode" ><Reset  {...this.props} {...this.state} /></Route>

            <AuthenticatedPharmacyRoute path="/condidat/:id" {...this.state}>
              <MapCandidatesInRadiusWrapper {...this.props} {...this.state} />
            </AuthenticatedPharmacyRoute>
            
            <AuthenticatedCandidateRoute path="/offer/:id" {...this.state}>
              <OfferDisplayWrapper {...this.props} {...this.state} />
            </AuthenticatedCandidateRoute>

            <Route path="/*" >
              <Redirect to="/" />
            </Route>
          </Switch>
        </div>
        {altNav && !user ? <Nav navbar className="d-flex justify-content-center absolute-bottom navbar-expand text-white bg-primary p-2">
          <span>Meetpharma - Tous droits réservés | <a href="/cgv" className="text-white">CGV</a> | <a href="/cgu" className="text-white">CGU</a> | <a href="/rgpd" className="text-white">RGPD</a> </span>
        </Nav> :
          <div className="d-lg-none d-flex" style={{ display: 'block', padding: '20px', height: '50px', width: '100%' }}>
            {/* the div is here so elements don't get hidden by the bottom navbar*/}
            <Nav navbar className="d-lg-none d-md-flex d-flex justify-content-center fixed-bottom navbar-expand shadow-lg w-100 py-2" style={{ backgroundColor: "#fff", background: "linear-gradient(top, #fff 0%, #eee 100%)", borderBottom: "5px solid #e2025f" }}>
              {this.renderNavItems(user, isCandidate, isPharmacy, isAdmin, countUnread, 'col', 'd-none', pharmacy?.is_paid, true)}
            </Nav>
          </div>}
        <CookieConsent
          location="bottom"
          buttonText="J'ai compris"
          cookieName="cookie-consent"
          style={{ background: "#2B373B", zIndex: 9999, position: "fixed", bottom: "0" }}
          buttonStyle={{ color: "white", background: "#e2025f" }}
          expires={999}
        >
          Ce site utilise des cookies pour fonctionner. En poursuivant la navigaition vous en accéptez leur utilisation. Consultez notre <a href="/privacy">politique de confidentialité</a> pour plus d'infos.
        </CookieConsent>
      </div >
    )
  }
}

export default App